import { CountryName } from 'src/types/country-name';
import { CurrencyType } from 'src/types/currency';

export const COUNTRY_NAME_BY_CURRENCY_MAP: Record<CurrencyType, CountryName> = {
  AED: 'emirates',
  SAR: 'saudi',
  KWD: 'kuwait',
  BHD: 'bahrain',
  QAR: 'qatar',
};
